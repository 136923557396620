import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Dropdown, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import { Link, useNavigate, } from 'react-router-dom'

// cookieManager
import cookieManager from '../../../common/cookieManager'

import commonSession from '../../../common/commonSession'

// import api 
import Notice from '../../../module/notice/Notice'

import { unescapeHtml,numberToKorean,scrollToTop } from '../../../lib/global_function'

const Notification = () => {

   const navigate = useNavigate();

   var token = cookieManager.getCookie('token')

   var this_url = window.location.href;

   console.log(this_url);

   if (this_url.indexOf('localhost:3000') != -1) {
      token = '1231231';
   }

   console.log(token);

   const [list, setList] = useState([]);
   const [page, setPage] = useState(1);
   var pageSize = 1000;

   let notice = new Notice(process.env.REACT_APP_NOTICEAPI, {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + token
   });

   useEffect(() => {
      if (typeof token == 'undefined' || token == '') {
         alert('밈비 사이트에 로그인 후 이용이 가능합니다.');
         return window.location.href = 'https://mym-b.com/login?returnUrl='+encodeURI(window.location.href);
      }

      if (token != '1231231') {
         loadList(page);
      }

      scrollToTop();
   }, [])

   const loadList = async (page) => {
      var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
      var result = await notice.findAll(param);
      setList(result.data.data);
   }

   return (
      <>
         <div id='content-page' className='content-page notice-page'>
            <Container>
               <Row>
                  <Col sm="12">
                     <h4 className="card-title mb-3"> <Link onClick={() => navigate(-1)} className="material-symbols-outlined back-btn">chevron_left</Link>공지사항</h4>
                  </Col>
                  <Col sm="12">
                     {list && list.map((l, i) => {
                         var dt = new Date(l.createdAt);
                         var year = dt.getFullYear();
                         var month = dt.getMonth() +1;
                         var day = dt.getDate() +1;

                        return <Card className="notice-item">
                           <Card.Body>
                              <ul className="notification-list m-0 p-0">
                                 <li className="d-flex align-items-center justify-content-between">
                                    <div className="w-100">
                                       <div className="d-flex justify-content-between">
                                          <div className="">
                                             <h6 className="notice-title">{l.title ?? '2024년 예정된 신작 관련 안내'}</h6>
                                             <p className="mb-0 notice-date">{year+'.'+month+'.'+day ?? '2023.11.14'}</p>
                                          </div>
                                          <div className="d-flex align-items-center">
                                             <Link to={`/notification/view?noticeId=`+(l._id ?? '')} className="material-symbols-outlined notice-icon">chevron_right</Link>
                                          </div>
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </Card.Body>
                        </Card>
                     })}
                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default Notification