import React, { useState } from "react";
import {
  Dropdown,
  Nav,
  Form,
  Card,
  Container,
  Image,
  Modal,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

//image
import icon_user from "../../../../assets/clients-images/custom/icon_user.svg";
import icon_bell from "../../../../assets/clients-images/custom/icon_bell.svg";
import chevron_left from "../../../../assets/clients-images/custom/chevron_left.svg";

// cookieManager
import cookieManager from '../../../../common/cookieManager'

//Componets
import CustomToggle from "../../../dropdowns";
// import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const Header = () => {
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  const queryParameters = new URLSearchParams(window.location.search)
  const fundingId = queryParameters.get("fundingId")
  const fundingName = queryParameters.get("fundingName")
  const totalAmount = queryParameters.get("totalAmount")
  const memberCnt = queryParameters.get("memberCnt")
  const thumbnailLink = queryParameters.get("thumbnailLink")

  //location
  let location = useLocation();

  var token = cookieManager.getCookie('token')

  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center gap-3  pb-2 pb-lg-0">

              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                {/* <Image
                    className="avatar-60"
                    src={logo}
                    alt=""
                    loading="lazy"
                    style={{objectFit:"contain"}}
                  /> */}

                <h3
                  className="logo-title d-none d-sm-block color-00FF00 pretendard-b"
                  data-setting="app_name"
                >
                  MYMComm
                </h3>
                <h5 className="sub-logo-title">: WEB3 Project Community</h5>
              </Link>
              {/* <Link
                to="#"
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  menu
                </div>
              </Link> */}
            </div>
            <Link
              to="/"
              className="gap-2 iq-header-logo m-logo"
            >
              { fundingName  ?  <><Link onClick={() => navigate(-1)} className="material-symbols-outlined back-btn top-back-btn"><img src={chevron_left} alt="뒤로가기 버튼"></img></Link><span className="funding-title">{fundingName}</span></>: <><h3  className="logo-title d-sm-block color-00FF00 pretendard-b"  data-setting="app_name">  MYMComm</h3><h5 className="sub-logo-title">: WEB3 Project Community</h5></>}
              
            </Link>
            <ul className="navbar-nav navbar-list">
              <Nav.Item as="li">
                <Link className={`${location.pathname === '/notification' ? 'active' : ''} nav-link `} aria-current="page" to="/notification">
                  <img src={icon_bell} className="w35-35" alt="profile" />
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  to={(typeof token == 'undefined' || token == '') ? "https://mym-b.com/login?returnUrl=" + encodeURI(window.location.href) : "https://mym-b.com/mymbId"} target="_blank"
                  className="dropdown-toggle d-flex align-items-center"
                >
                  {(typeof token == 'undefined' || token == '') ? '로그인' : <img src={icon_user} className="w35-35" alt="profile" />}
                </Link>
              </Nav.Item>
            </ul>
          </Container>
        </Nav>
      </div>
    </>
  );
};

export default Header;
