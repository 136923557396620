import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useLocation } from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'

// cookieManager
import cookieManager from '../../../common/cookieManager'

import commonSession from '../../../common/commonSession'

// import api 
import Project from '../../../module/project/Project'
import User from '../../../module/user/User'

// images
import gi1 from '../../../assets/images/page-img/funding_001.png'
import gi2 from '../../../assets/images/page-img/funding_002.jpg'
import gi3 from '../../../assets/images/page-img/funding_003.png'

import { numberToKorean } from '../../../lib/global_function'

const Groups = () => {

    var token = cookieManager.getCookie('token')

    var this_url = window.location.href;

    console.log(this_url);

    if (this_url.indexOf('localhost:3000') != -1) {
        token = '1231231';
    }

    //location
    let location = useLocation();

    const [list, setList] = useState([]);
    const [memberName, setMemberName] = useState('');
    const [page, setPage] = useState(1);
    var pageSize = 1000;

    let project = new Project(process.env.REACT_APP_PROJECTAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer ' + token
    });


    let user = new User(process.env.REACT_APP_USERAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer ' + token
    });


    useEffect(() => {
        if (typeof token == 'undefined' || token == '') {
            alert('밈비 사이트에 로그인 후 이용이 가능합니다.');
            return window.location.href = 'https://mym-b.com/login?returnUrl=' + encodeURI(window.location.href);
        }

        if (token != '1231231') {
            loadList(page);
            loadUserInfo();
        }

        document.getElementById("root").scrollTo(0, 0);

    }, [])

    const loadList = async (page) => {
        var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1);
        var result = await project.findAll(param);
        setList(result.data.data);
    }

    const loadUserInfo = async () => {
        var result = await user.getUserData();

        // 세션에 저장 후  사용하기.
        commonSession.setSession(result.data.data.mymId, result.data.data.token, result.data.data.nickName, result.data.data.uid, result.data.data.firstName, result.data.data.lastName, result.data.data.inviterEmail, result.data.data.enterprise, result.data.data.certificated)
        setMemberName(result.data.data.nickName)
    }

    return (
        <>
            <div className="content-page-title">
                <p className="page-title">{memberName ?? '밈비'}님,</p>
                <p className="page-title">프로젝트는 잘 진행되고 있나요?</p>
            </div>
            <div id="content-page" className="content-page">
                <Container>
                    <div className="d-grid gap-3 d-grid-template-1fr-19">
                        {list && list.map((l, i) => {
                            var max_genr = 2;
                            if (l.Genres != '') {
                                var genres = l.Genres.split('#');
                            }

                            return <Card className="mb-0">
                                <Card.Body className="text-center">
                                    <div className="badge-box text-left d-flex align-items-center">
                                        {l.extraData.isMember ? <span className="badge buy-success">구매완료</span> : ''}
                                        {genres && genres.map((gen, ind) => {
                                            if (ind <= max_genr) {
                                                return <span className="badge genre-badge">{gen}</span>
                                            }
                                        })}
                                        {l.extraData.lastStatus ? <span className="badge ing-badge">{l.extraData.lastStatus}</span> : ''}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="group-icon">
                                            <img src={l.ThumbnailLink} alt="profile-img" className="rounded-circle img-fluid w50-50" style={{ objectFit: "cover" }} />
                                        </div>
                                        <div className="group-data">
                                            <div className="group-info pt-1 pb-1">
                                                <p className="text-left">{l.WriterName ?? '작가명'}</p>
                                                <h4 className="text-left project-title">
                                                    {l.extraData.isMember ? <Link className={`${location.pathname === '/process' ? 'active' : ''} nav-link text-white`} aria-current="page" to={`/process?` + encodeURIComponent(`fundingId=` + l.FundingId + `&writerName=` + l.WriterName + `&thumbnailLink=` + l.ThumbnailLink + `&fundingName=` + l.SynopsisTitle + `&memberCnt=` + (l.extraData.memberCnt ?? 0) + `&totalAmount=` + (Number(l.extraData.totalFundingAmount) ?? '19380'))}>
                                                        {l.SynopsisTitle ?? '제목'}
                                                    </Link> : <Link className={`${location.pathname === '/process' ? 'active' : ''} nav-link text-white b`} aria-current="page" to={`https://mym-b.com/funding/View?pollingResultId=` + l.PollingResultId}>
                                                        {l.SynopsisTitle ?? '제목'}
                                                    </Link>}
                                                </h4>
                                            </div>
                                            <div className="group-details d-inline-block pb-3">
                                                <ul className="d-flex align-items-center justify-content-start list-inline m-0 p-0">
                                                    <li className="pe-3">
                                                        <p className="mb-0">회원</p>
                                                        <h6>{l.extraData.memberCnt ?? 0}</h6>
                                                    </li>
                                                    <li className="pe-3">
                                                        <p className="mb-0">펀딩금액</p>
                                                        <h6> {numberToKorean(Number(l.extraData.totalFundingAmount)) ?? '19,380'}원</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {l.extraData.isMember ? <button type="submit" className="btn btn-primary d-block w-100"><Link className={`${location.pathname === '/process' ? 'active' : ''} nav-link text-white`} aria-current="page" to={`/process?` + encodeURIComponent(`fundingId=` + l.FundingId + `&writerName=` + l.WriterName + `&thumbnailLink=` + l.ThumbnailLink + `&fundingName=` + l.SynopsisTitle + `&memberCnt=` + (l.extraData.memberCnt ?? 0) + `&totalAmount=` + (Number(l.extraData.totalFundingAmount) ?? '19380'))}>
                                        커뮤니티 홈
                                    </Link></button> : <button type="submit" className="btn btn-gray d-block w-100"><Link className={`${location.pathname === '/process' ? 'active' : ''} nav-link text-white b`} aria-current="page" to={`https://mym-b.com/funding/View?pollingResultId=` + l.PollingResultId}>
                                        참여할래요
                                    </Link></button>}
                                </Card.Body>
                            </Card>

                        })}

                    </div>
                </Container >
            </div >
        </>
    )

}

export default Groups;