//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"


// Redux Selector / Action
import { useDispatch } from 'react-redux';

import { useEffect } from 'react';
// import state selectors
import { setSetting } from './store/setting/actions'


function App(props) {
  const dispatch = useDispatch()
  dispatch(setSetting())


  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, []);

  return (
    <div className="App">
      {/* <IndexRouters /> */}
      {props.children}
    </div>
  );
}

export default App;
