import axios from "axios";
import Event from "../event/Event";

// ProjectNotice class  
class ProjectNotice extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

    async findAll(params){

        //전체리스트 가져오기
        const url = `${this.baseUrl}/all`+params;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

    async findCateAll(cate,params){
        //카테고리 기준 전체리스트 가져오기
        const url = `${this.baseUrl}/`+cate;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method,'' ,params);//make option
    }

    async findStateAll(params){ 
        //상태 기준 전체리스트 가져오기
        const url = `${this.baseUrl}/state`+params;
        const method = 'GET'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }

}

export default ProjectNotice;