import axios from "axios";
import Event from "../../module/event/Event";

// Notice class  
class Notice extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

}

export default Notice;