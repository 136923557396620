import React, { useState,useEffect } from 'react'
import { Container, Row, Col, Card, Dropdown, Button, Modal, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { Link,  useNavigate, } from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'


// cookieManager
import cookieManager from '../../../common/cookieManager'

// import global_function
import { unescapeHtml,numberToKorean,scrollToTop } from '../../../lib/global_function'

import commonSession from '../../../common/commonSession'

// import api 
import Notice from '../../../module/notice/Notice'


const NoticeDetail = () => {

    const queryParameters = new URLSearchParams(window.location.search)
    const noticeId = queryParameters.get("noticeId")
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [data, setData] = useState([]);
    const [createdAt, setCreatedAt] = useState('');
    var token = cookieManager.getCookie('token')

    const navigate = useNavigate();

    let notice = new Notice(process.env.REACT_APP_NOTICEAPI, {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': 'Bearer ' + token
     });
  
     useEffect(() => {
        if (typeof token == 'undefined' || token == '') {
           alert('밈비 사이트에 로그인 후 이용이 가능합니다.');
           return window.location.href = 'https://mym-b.com/login?returnUrl='+encodeURI(window.location.href);
        }
  
        if (token != '1231231') {
           loadData(noticeId);
        }
        
        scrollToTop();
     }, [])


     const loadData = async (noticeId) => {
        var param = 'daoNoticeId='+noticeId;
        var result = await notice.findOne(param);

        var dt = new Date(result.data.data.createdAt);
        var year = dt.getFullYear();
        var month = dt.getMonth() +1;
        var day = dt.getDate() +1;

        setCreatedAt(year+'.'+month+'.'+day);
        setData(result.data.data);
     }

    return (
        <>
            <div id="content-page" className="content-page notice-view-page">
                <Container>
                    <Row>
                        <Col sm="12">
                            <h4 className="card-title mb-3"> <Link onClick={() => navigate(-1)} className="material-symbols-outlined back-btn">chevron_left</Link>공지사항</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="post-item">
                                        <div className="user-post-data py-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h5 className="mb-0 d-inline-block notice-title">{data.title ?? ''}</h5>
                                                            <p className="mb-0 write-date">{createdAt ?? '2023.11.15'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-post">
                                            {/* <img className="img-fluid w-100 post-image" src={img7} alt="postimage" /> */}
                                            <div className="notice-content" dangerouslySetInnerHTML={{__html: unescapeHtml(data.content) ?? '문테크놀러지라는 제작사에서 담당하기로 했음 블라블라 엠젯 스님 박건우의 주 배경인 사찰이 대한 조계종 협회의 협력으로 강남구 소림사를 블라블라 문테크놀러지라는 제작사에서 담당하기로 했음 블라블라 엠젯 스님 박건우의 주 배경인 사찰이 대한 조계종 협회의 협력으로 강남구 소림사를 블라블라 문테크놀러지라는 제작사에서 담당하기로 했음 블라블라 엠젯 스님 박건우의 주 배경인 사찰이 대한 조계종 협회의 협력으로 강남구 소림사를 블라블라'}}>
                                            </div>
                                        </div>
                                        {/* <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle}>
                                                                    <img src={icon1} className="img-fluid" alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="py-2 show">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt="" /></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle}>
                                                                    140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu">
                                                                    <Dropdown.Item className="dropdown-item" href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item className="dropdown-item" href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}>
                                                                20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu">
                                                                <Dropdown.Item className="dropdown-item" href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item className="dropdown-item" href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                            <hr />
                                            <ul className="post-comments p-0 m-0">
                                                <li className="mb-2">
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={img10} alt="userimg" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Monty Carlo</h6>
                                                            <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="">like</Link>
                                                                <Link to="">reply</Link>
                                                                <Link to="">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="d-flex">
                                                        <div className="user-img">
                                                            <img src={img11} alt="userimg" className="avatar-35 rounded-circle img-fluid" />
                                                        </div>
                                                        <div className="comment-data-block ms-3">
                                                            <h6>Paul Molive</h6>
                                                            <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                                                <Link to="">like</Link>
                                                                <Link to="">reply</Link>
                                                                <Link to="">translate</Link>
                                                                <span> 5 min </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <form className="comment-text d-flex align-items-center mt-3" action="">
                                                <input type="text" className="form-control rounded" placeholder="Enter Your Comment" />
                                                <div className="comment-attagement d-flex">
                                                    <Link to=""><i className="ri-link me-3"></i></Link>
                                                    <Link to=""><i className="ri-user-smile-line me-3"></i></Link>
                                                    <Link to=""><i className="ri-camera-line me-3"></i></Link>
                                                </div>
                                            </form>
                                        </div> */}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default NoticeDetail;