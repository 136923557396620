import { Cookies } from 'react-cookie';

const cookies = new Cookies();

var cookieManager = (function () {

	var setCookie = function (name, value, options) {
		return cookies.set(name, value, { ...options });
	}

	var getCookie = function (name) {
		return cookies.get(name);
	}

	return {
        setCookie: setCookie,
        getCookie: getCookie
    }

})();

export default cookieManager;