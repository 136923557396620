export const escapeHtml =(text) => {

    if(typeof text != 'undefined' && text != ''){
        text = text.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll('"', "'");
    }
    
    return text
  }

export const unescapeHtml = (text) => {
    if(typeof text != 'undefined' && text != ''){
        text = text.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>').replaceAll("'", '"');
    }

    return text;
}

export const scrollToTop = () => {
    console.log('scrollToTop');
    return window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const numberToKorean = (number) => {
    var inputNumber  = number < 0 ? false : number;
    var unitWords    = ['', '만', '억 ', '조 ', '경 '];
    var splitUnit    = 10000;
    var splitCount   = unitWords.length;
    var resultArray  = [];
    var resultString = '';

    for (var i = 0; i < splitCount; i++){
         var unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
        unitResult = Math.floor(unitResult);
        if (unitResult > 0){
            resultArray[i] = unitResult;
        }
    }

    for (var i = 0; i < resultArray.length; i++){
        if(!resultArray[i]) continue;
        resultString = String(resultArray[i]) + unitWords[i] + resultString;
    }

    return resultString;
}