import axios from "axios";
import Event from "../../module/event/Event";

// User class  
class User extends Event{

    constructor(baseUrl, headers) {
        super(baseUrl, headers);
    }

    async getUserData(){

        //전체리스트 가져오기
        const url = `${this.baseUrl}`;
        const method = 'POST'; // api 전달 method

        return this.sendAxios(url,method);//make option
    }
}

export default User;