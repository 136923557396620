import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { Link, useLocation } from 'react-router-dom'
import ReactFsLightbox from 'fslightbox-react';

// cookieManager
import cookieManager from '../../../common/cookieManager'
import commonSession from '../../../common/commonSession'


// import api 
import Progress from '../../../module/project/Progress'
import ProjectNotice from '../../../module/project/ProjectNotice'

// import global_function
import { unescapeHtml,numberToKorean,scrollToTop } from '../../../lib/global_function'


// images
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import g1 from '../../../assets/images/page-img/g1.jpg'
import g2 from '../../../assets/images/page-img/g2.jpg'
import g3 from '../../../assets/images/page-img/g3.jpg'
import g4 from '../../../assets/images/page-img/g4.jpg'
import g5 from '../../../assets/images/page-img/g5.jpg'
import g6 from '../../../assets/images/page-img/g6.jpg'
import g7 from '../../../assets/images/page-img/g7.jpg'
import g8 from '../../../assets/images/page-img/g8.jpg'
import g9 from '../../../assets/images/page-img/g9.jpg'
import img51 from '../../../assets/images/page-img/51.jpg'
import img52 from '../../../assets/images/page-img/52.jpg'
import img53 from '../../../assets/images/page-img/53.jpg'
import img54 from '../../../assets/images/page-img/54.jpg'
import img55 from '../../../assets/images/page-img/55.jpg'
import img56 from '../../../assets/images/page-img/56.jpg'
import img57 from '../../../assets/images/page-img/57.jpg'
import img58 from '../../../assets/images/page-img/58.jpg'
import img59 from '../../../assets/images/page-img/59.jpg'
import img60 from '../../../assets/images/page-img/60.jpg'
import img61 from '../../../assets/images/page-img/61.jpg'
import img62 from '../../../assets/images/page-img/62.jpg'
import img64 from '../../../assets/images/page-img/64.jpg'
import img65 from '../../../assets/images/page-img/65.jpg'
import img63 from '../../../assets/images/page-img/63.jpg'

import test_img from '../../../assets/clients-images/test-img.svg'

import icon_project from '../../../assets/clients-images/custom/icon_project.svg'
import icon_project_noti from '../../../assets/clients-images/custom/icon_project_noti.svg'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;

const UserProfile = () => {

   const queryParameters = new URLSearchParams(decodeURIComponent(window.location.search))
   const fundingId = queryParameters.get("fundingId")
   const fundingName = queryParameters.get("fundingName")
   const totalAmount = queryParameters.get("totalAmount")
   const memberCnt = queryParameters.get("memberCnt")
   const thumbnailLink = queryParameters.get("thumbnailLink")
   const writerName = queryParameters.get("writerName")

   const [show, setShow] = useState(false);
   const [activeTab, setActiveTab] = useState('project-noti-list')
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const [list, setList] = useState([]);
   const [dateList, setDateList] = useState([]);
   const [noticeList, setNoticeList] = useState([]);
   const [boardCnt, setBoardCnt] = useState(0);
   const [page, setPage] = useState(1);
   var pageSize = 1000;

   var token = cookieManager.getCookie('token')

   var this_url = window.location.href;

   if (this_url.indexOf('localhost:3000') != -1) {
      token = '1231231';
   }

   //location
   let location = useLocation();

   let progress = new Progress(process.env.REACT_APP_PROGRESSAPI, {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + token
   });


   let projectNotice = new ProjectNotice(process.env.REACT_APP_PROJECTNOTICEAPI, {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': 'Bearer ' + token
   });

   useEffect(() => {
      if (typeof token == 'undefined' || token == '') {
         alert('밈비 사이트에 로그인 후 이용이 가능합니다.');
         return window.location.href = 'https://mym-b.com/login?returnUrl=' + encodeURI(window.location.href);
      }

      if (token != '1231231') {
         loadList(page, fundingId);
         loadNoticeList(page, fundingId);
      }

      document.getElementById('project-progress-list-tab').click();
      scrollToTop();
      
   }, [])

   const [imageController, setImageController] = useState({
      toggler: false,
      slide: 1
   });

   function setTab(e) {
      setActiveTab(e);

      var list_array = document.getElementsByClassName("list-box");

      for (var i = 0; i < list_array.length; i++) {
         if (list_array[i].className.includes(e)) {
            list_array[i].classList.remove('d-none');
         } else {
            list_array[i].classList.add("d-none");
         }
      }
   }

   function imageOnSlide(number) {
      setImageController({
         toggler: !imageController.toggler,
         slide: number
      });
   }

   const loadList = async (page, fundingId) => {
      var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1) + '&fundingId=' + fundingId;
      var result = await progress.findAll(param);
      var array = result.data.data;

      if (result.data.data != null) {
         array = await result.data.data.sort(function (a, b) {
            return a.start_at - b.start_at;
         })
      }

      var date_arr = [];

      array.map((l, i) => {
         if (!date_arr.includes(l.start_at)) {
            date_arr.push(l.start_at)
         }
      })

      setList(array);
      setDateList(date_arr);
      setBoardCnt(array.length);
   }

   const loadNoticeList = async (page, fundingId) => {
      var param = '?pageSize=' + pageSize + '&currentPage=' + (page - 1) + '&fundingId=' + fundingId;
      var result = await projectNotice.findAll(param);
      setNoticeList(result.data.data);
   }

   return (
      <>
         <Container>
            <Row>
               <Col sm={12}>
                  <Card className="profile-card">
                     <Card.Body className="profile-page p-0">
                        <div className="profile-header">
                           <div className="user-detail text-center">
                              <div className="profile-img">
                                 <img loading="lazy" src={thumbnailLink} alt="profile-img1" className="avatar-130 img-fluid project-main-img" />
                              </div>
                              <div className="profile-detail">
                                 <h3>{writerName ?? '프로젝트명'}</h3>
                              </div>
                           </div>
                           <div className="profile-info d-flex align-items-center justify-content-between position-relative">
                              <div className="social-info">
                                 <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                    <li className="text-center ps-3">
                                       <p className="mb-0">{boardCnt ?? 0}</p>
                                       <h6>게시물</h6>
                                    </li>
                                    <li className="text-center ps-3">
                                       <p className="mb-0">{memberCnt ?? 0}</p>
                                       <h6>회원수</h6>
                                    </li>
                                    <li className="text-center ps-3">
                                       <p className="mb-0">{numberToKorean(Number(totalAmount)) ?? 0}</p>
                                       <h6>펀딩금액</h6>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
               <Nav sm={12} lg={12} style={{ width: "100%" }}>
                  <Nav variant="tabs" className="mb-3 flex-column-none" id="nav-tab" role="tablist" style={{ width: "100%" }}>
                     <Nav.Link eventKey="first" variant="d-flex align-items-center" id="project-progress-list-tab" data-bs-toggle="tab" data-bs-target="#project-progress-list" type="button" role="tab" aria-controls="project-progress" aria-selected="true" style={{ width: "50%", textAlign: "center" }} onClick={() => setTab('project-progress-list')}>
                        <img src={icon_project} alt="프로젝트 아이콘" />
                     </Nav.Link>
                     <Nav.Link eventKey="second" variant="" id="project-notice-list-tab" data-bs-toggle="tab" data-bs-target="#project-notice-list" type="button" role="tab" aria-controls="project-notice" aria-selected="true" style={{ width: "50%", textAlign: "center" }} onClick={() => setTab('project-notice-list')}>
                        <img src={icon_project_noti} alt="프로젝트 공지 아이콘" />
                     </Nav.Link>
                  </Nav>
               </Nav>
               <Row className="project-progress-list list-box">
                  {/* 배열 반복문 넣을 공간 -- 시작 */}
                  {dateList && dateList.map((date, index) => {
                     var dt = new Date(date);
                     var year = dt.getFullYear();
                     var month = dt.getMonth() +1;
                     var day = dt.getDate() +1;

                     return <Card className="card-dark project-list">
                        <div className="card-header d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">{year+`년 `+month+`월 `+day+`일`}</h4>
                           </div>
                        </div>
                        <Card.Body>
                           <div className="ui-block-content">
                              <div className="widget w-socials">
                                 {list && list.map((l, i) => {
                                    if (date == l.start_at) {
                                       return <div className="social-item btn btn-dark">
                                          <div class="content-box" dangerouslySetInnerHTML={{ __html: unescapeHtml(l.explain.replaceAll('\n','<br/>')) ?? '드라마 프리프로덕션 - 제작 투자 완료' }}>
                                          </div>
                                       </div>
                                    }
                                 })}
                              </div>
                           </div>
                        </Card.Body>
                     </Card>
                  })}
               </Row>
               <Row className="project-notice-list d-none list-box">
                  {noticeList && noticeList.map((l, i) => {
                     return <Card className="card-dark project-notice-list">
                        <div className="card-header d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">{l.title ?? '엠젯 스님 박건우의 제작사 확정'}</h4>
                           </div>
                        </div>
                        <Card.Body>
                           <div className="ui-block-content">
                              <div className="widget w-socials">
                                 <div className="notice-item">
                                    <div class="content-box" dangerouslySetInnerHTML={{ __html: unescapeHtml(l.content.replaceAll('\n','<br/>')) ?? '문테크놀러지라는 제작사에서 담당하기로 했음 블라블라 엠젯 스님 박건우의 주 배경인 사찰이 대한 조계종 협회의 협력으로 강남구 소림사를 블라블라 문테크놀러지라는 제작사에서 담당하기로 했음 블라블라 엠젯 스님 박건우의 주 배경인 사찰이 대한 조계종 협회의 협력으로 강남구 소림사를 블라블라 문테크놀러지라는 제작사에서 담당하기로 했음 블라블라 엠젯 스님 박건우의 주 배경인 사찰이 대한 조계종 협회의 협력으로 강남구 소림사를 블라블라' }}>
                                    </div>
                                    <div className="time-info">
                                       <span className="write-date">{l.createdAt ?? '11시 39분 오후'}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </Card.Body>
                     </Card>
                  })}
               </Row>
            </Row>
         </Container>
      </>
   )

}

export default UserProfile;