import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Row, Col, Container, Nav } from 'react-bootstrap'


import icon_home from '../../../../assets/clients-images/custom/icon_home.svg'
import icon_settings from '../../../../assets/clients-images/custom/icon_settings.svg'
import icon_noti from '../../../../assets/clients-images/custom/icon_noti.svg'


const Navbar = (props) => {
    //location
    let location = useLocation();

    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('dark');
    if (mode !== null) {
        document.body.classList = ''
        switch (mode) {
            case "true":
                document.body.classList = 'dark'
                break;
            case "false":
                document.body.classList = ''
                break;
            default:
                document.body.classList = ''
                break;
        }
    }
    return (
        <>
            <div className="iq-bottom-navbar">
                <Nav
                    expand="lg"
                    variant="light"
                    className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
                >
                    <Container fluid className="navbar-inner w-100">
                        <ul className="navbar-nav navbar-list align-items-center justify-content-between w-100">
                            <Nav.Item as="li">
                                <Link to="/" className="d-flex align-items-center">
                                    <img src={icon_home} className="bottom-nav-icon" alt="icon_home"></img>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/notification' ? 'active' : ''} nav-link `} aria-current="page" to="/notification">
                                    <img src={icon_noti} className="bottom-nav-icon" alt="icon_noti"></img>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link className={`${location.pathname === '/notification' ? 'p-0 active' : 'p-0'} nav-link `} aria-current="page" to="/notification">
                                    <img src={icon_settings} className="bottom-nav-icon" alt="icon_settings"></img>
                                </Link>
                            </Nav.Item>
                        </ul>
                    </Container>
                </Nav>
            </div>
        </>
    )
}

export default (Navbar)